<template>
  <div class="container">
      <div class="teach-organ">
          <div class="head-title">
              <div class="topic">教学资源合作机构入驻</div>
              <div class="topic-tips">如果您有优质课程资源，可成为资源提供方，提供优质课程的同时，可获得收益
              </div>
          </div>
          <div class="inner-box">
              <div class="inner-header">入驻要求</div>
              <ul class="inner-rules">
                  <li v-for="(item, index) in teachRuleList" :key="index">
                      <span class="idx">{{index+1}}</span>
                      <span class="rule">{{item}}</span>

                  </li>
              </ul>
          </div>
          <div class="apply-btn" @click="apply('teach')">申请入驻</div>
      </div>
      <div class="distributor-organ">
          <div class="head-title">
              <div class="topic">参培机构入驻</div>
              <div class="topic-tips">如果你的学员或员工需要获得优质培训服务，由此进入申请成为参培机构即可获得
              </div>
          </div>
          <div class="fx-item">
              <!-- <div class="tip-icon"><p>参培机构入驻</p></div> -->
              <div class="inner-box">
              <div class="inner-header">入驻要求</div>
              <ul class="inner-rules">
                  <li v-for="(item, index) in OrganRuleList" :key="index">
                      <span class="idx">{{index+1}}</span>
                      <span class="rule">{{item}}</span>

                  </li>
              </ul>
          </div>
          </div>
          <!-- <div class="sx-item">
              <div class="tip-icon"><p>实训基地入驻</p></div>
              <div class="topic-tips">如果你的学员或员工需要获得优质培训服务，由此计入申请成为参培单位即可获得
              </div>
              <div class="inner-box">
              <div class="inner-header">入驻要求</div>
              <ul class="inner-rules">
                  <li v-for="(item, index) in distributor_sx_list" :key="index">
                      <span class="idx">{{index+1}}</span>
                      <span class="rule">{{item}}</span>

                  </li>
              </ul>
          </div>
          </div> -->
          <div class="apply-btn" @click="apply('distributor')">申请入驻</div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            teachRuleList: [
                '依法在中国境内登记，具有独立法人资格，具有规范的财务制度和管理制度，无重大违法、违规、失信等不良行为记录。符合教培中心相应职业技能培训规范中有关教学资源提供机构的标准要求。',
                '具备专业的课程开发师资及专家团队，拥有课程的自主知识产权或拥有产权方授予的销售权。',
                '自愿按照人力资源和社会保障部社会保障能力建设中心的职业技能培训规范和管理要求提供相应的教学资源和服务。',
                '家庭服务业（康养产业）相关国家职业技能标准的参标单位、社会培训评价组织优先考虑。',
            ],
            OrganRuleList:[
                '具有独立法人资格，具备教育部门或人社部门颁发的家庭服务业（康养产业）相关职业（工种）办学许可证或其社会团体法人登记证书、事业单位法人登记证书、工商登记的营业执照中规定的业务范围包含人才培养、培训、教育咨询、人力资源服务等内容。无重大违法、违规、失信等不良行为记录。符合教培中心相应职业技能培训规范中有关参培机构的标准要求。',
                '具有能够承担相应培训任务的理论课或实操课师资、设施设备及场地资源。',
                '具备较为完善的组织管理制度、财务制度，以及健全的教学管理制度，包括培训服务管理、培训学员管理、培训师资管理、培训档案管理等制度管理体系。',
                '自愿接受人力资源和社会保障部社会保障能力建设中心对培训全过程的管理和监督。',
                '家庭服务业（康养产业）相关国家职业技能标准的参标单位、社会培训评价组织优先考虑。'
            ],
            distributor_sx_list: [
                '注册时间6个月以上，有优质线下实训渠道资源',
                '组织机构代码/营业执照/税务登记证等原件扫描件等材料',
                '企业实名认证',
                '法人身份信息',
                '师资信息',
                '入驻承诺书'
            ]
        }
    },
    methods: {
        apply(type){
            if(type == 'teach'){
                // 教资
                this.$router.push('/resourceInstitutionsIn')
            } else {
                // 分销商
                this.$router.push('/trainInstitutionsIn')
            }
            console.log('---->跳转吧少年')
        }
    }

}
</script>

<style lang="less" scoped>
@import '../assets/css/institutionsIn.module.less';
</style>